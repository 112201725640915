@import "../../../styles/variables";

.bold {
  font-weight: 900;
  color:       white !important;
}

.beforeRelease {
  :global {
    .container {
      max-width: 1220px;
    }
  }
}

.kayoralpha {
  margin-top: 72px;
  .panel {
    padding:       24px 26px 2px 38px;
    border-radius: 20px;
    background:    rgba(#000000, 60%);
    h4 {
      font-size:     30px;
      color:         #FFD600;
      line-height:   0.9;
      margin-bottom: 14px;
    }
    p {
      color:         white;
      text-align:    left;
      margin-bottom: 24px;
      font-weight:   800;
      line-height:   1.2;
      span {
        font-weight: 900;
        color:       $yellow;
      }
    }
  }
  .title {
    display:       inline-block;
    margin-bottom: 40px;
    h1 {
      color:          white;
      letter-spacing: -3px;
      font-family:    'Libre Franklin', sans-serif;
      max-width:      750px;
      text-shadow:    0 4px 0 #000000;
    }
  }
  .black {
    text-align: right;
    max-width:  512px;
    p {
      margin-top:     -10px;
      letter-spacing: -1.6px;
      margin-bottom:  0;
      color:          black;
      font-size:      50px;
      font-weight:    300;
      padding:        0 10px 2px 10px;
      position:       relative;
      display:        inline-block;
      margin-left:    auto;
      &:after {
        content:          '';
        position:         absolute;
        z-index:          -1;
        bottom:           -10%;
        left:             0;
        width:            100%;
        height:           94%;
        background-color: #FFD600;
      }
    }
  }
  .content {
    position:        relative;
    z-index:         2;
    display:         flex;
    align-items:     flex-start;
    justify-content: space-between;
  }
  .drop {
    display:        inline-block;
    color:          $yellow;
    background:     black;
    font-size:      100px;
    font-weight:    200;
    margin-bottom:  0;
    font-family:    'Libre Franklin', sans-serif;
    letter-spacing: -2.8px;
    padding:        0 0 10px 0;
  }
  .left {
    width: 47%;
  }
  .right {
    width:          47%;
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    position:       relative;
    z-index:        2;
    margin-top:     -186px;
  }
  .countdown {
    text-align:  right;
    max-width:   400px;
    margin-left: auto;
    .yellow {
      font-size:     30px;
      font-weight:   900;
      color:         black;
      margin-bottom: 2px;
      padding-right: 40px;
      display:       inline-block;
      position:      relative;
      z-index:       2;
      &:after {
        content:          '';
        position:         absolute;
        z-index:          -1;
        bottom:           14%;
        left:             0;
        width:            100%;
        height:           90%;
        background-color: $yellow;
      }
    }
    .timer {
      display: inline-block;
    }
    .play {
      width:         68px;
      cursor:        pointer;
      transition:    .3s linear;
      margin-bottom: 20px;
      &:hover, &:active {
        opacity: .7;
      }
    }
  }
  .association {
    text-align: center;
    margin:     28px 0;
    .logos {
      margin:          0 auto 42px auto;
      display:         flex;
      flex-direction:  column;
      align-items:     center;
      justify-content: center;
      img {
        &:nth-child(2) {
          width: 104px;
        }
        &:nth-child(3) {
          max-height: 56px;
          width:      144px;
          object-fit: cover;
        }
      }
    }
    p {
      font-size:     10px;
      margin-bottom: 6px;
      font-weight:   500;
      &.upper {
        font-weight:    600;
        font-size:      9px;
        text-transform: uppercase;
      }
    }
  }
  .released {
    background:    #ffd600;
    padding:       20px 16px 16px 16px;
    margin-left:   auto;
    margin-bottom: 48px;
    max-width:     403px;
    width: 403px;
    h3 {
      color:          black;
      font-size:      28px;
      font-weight:    900;
      display:        flex;
      align-items:    center;
      margin-bottom:  12px;
      letter-spacing: -0.8px;
      img {
        width:        30px;
        margin-right: 8px;
      }
    }
    button {
      font-size:   20px;
      color:       #ffd600;
      padding:     6px 2px;
      font-weight: 900;
      white-space: nowrap;
      background: transparent;
      cursor:      pointer;
      height: 70px;
      width: 95%;
      border: none
    }
  }
  .join {
    background:    #ffd600;
    padding:       20px 16px 40px 16px;
    margin-left:   auto;
    margin-bottom: 48px;
    max-width:     403px;
    h3 {
      color:          black;
      font-size:      28px;
      font-weight:    900;
      display:        flex;
      align-items:    center;
      margin-bottom:  12px;
      letter-spacing: -0.8px;
      img {
        width:        30px;
        margin-right: 8px;
      }
    }
    form {
      display:  flex;
      position: relative;
      gap:      12px 0;
    }
    input {
      background: white;
      font-size:  20px;
      padding:    8px 10px;
      color:      black;
      border:     1px solid transparent;
      &::placeholder {
        color: rgba(#000000, 50%);
      }
      &:focus {
        border: 1px solid rgba(#000000, 50%);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: black;
      }
    }
    button {
      font-size:   20px;
      color:       #ffd600;
      background:  black;
      padding:     6px 14px;
      font-weight: 900;
      white-space: nowrap;
      margin-left: 20px;
      border:      1px solid black;
      cursor:      pointer;
      transition:  .3s linear;
      &:hover, &:active {
        background: transparent;
        color:      black;
      }
    }
    .error {
      position:      absolute;
      left:          0;
      bottom:        -8px;
      color:         #FF0000;
      font-size:     20px;
      font-weight:   300;
      margin-bottom: 0;
      transform:     translate(0, 100%);
    }
  }
}


@media screen and (max-width: 1024px) {
  .kayoralpha {
    margin-top: 48px;
    .panel {
      padding: 24px 24px 2px 24px;
    }
    
    .join {
      form {
        flex-direction: column;
      }
      h3 {
        font-size: 20px;
      }
      input {
        font-size: 16px;
      }
      button {
        margin-left: 0;
        font-size:   16px;
      }
      .error {
        font-size: 16px;
      }
    }
    .title {
      h1 {
        max-width:      390px;
        letter-spacing: -1.3px;
        line-height: 0.82;
        text-shadow:    0 2px 0 #000000;
      }
    }
    .drop {
      margin:         -2px 0 0 0;
      font-size:      45px;
      padding:        5px 20px 8px 8px;
      letter-spacing: -1.8px;
    }
    .black {
      max-width: 276px;
      p {
        font-size:      24px;
        letter-spacing: -0.6px;
      }
    }
    .left {
      width: 56%;
    }
    .right {
      width: 42%;
    }
    .countdown {
      .play {
        width: 39px;
      }
      .yellow {
        font-size:      24px;
        letter-spacing: -1px;
        &:after {
          height: 108%;
        }
      }
    }
  }
  
}

@media screen and (max-width: 768px) {
  .kayoralpha {
    .join {
      margin-left: 0;
      .error {
        font-weight: 900;
      }
    }
    .released {
      background:    #ffd600;
      padding:       20px 16px 16px 16px;
      margin-left:   auto;
      margin-bottom: 48px;
      max-width:     100%;
      width: 100%;
      h3 {
        color:          black;
        font-size:      28px;
        font-weight:    900;
        display:        flex;
        align-items:    center;
        margin-bottom:  12px;
        letter-spacing: -0.8px;
        img {
          width:        30px;
          margin-right: 8px;
        }
      }
      button {
        font-size:   20px;
        color:       #ffd600;
        padding:     6px 2px;
        font-weight: 900;
        white-space: nowrap;
        background: transparent;
        cursor:      pointer;
        height: 70px;
        width: 95%;
        border: none
      }
    }
    .title {
      margin-bottom: 54px;
    }
    .content {
      flex-direction: column;
    }
    .left {
      width: 100%;
      p {
        line-height: 1.3;
      }
    }
    .right {
      width:         100%;
      max-width:     none;
      margin-top:    20px;
      margin-bottom: 20px;
    }
    .countdown {
      margin-bottom: 192px;
    }
  }
}


@media screen and (max-width: 567px) {
  .beforeRelease {
    background: black;
    &:before {
      content:    '';
      position:   absolute;
      z-index:    1;
      left:       0;
      top:        100vh;
      width:      100%;
      height:     20vh;
      transform:  translate(0, -100%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
    :global {
      .background {
        position: absolute;
      }
    }
    .released {
      background:    #ffd600;
      padding:       20px 16px 16px 16px;
      margin-left:   auto;
      margin-bottom: 48px;
      max-width:     100%;
      width: 100%;
      h3 {
        color:          black;
        font-size:      28px;
        font-weight:    900;
        display:        flex;
        align-items:    center;
        margin-bottom:  12px;
        letter-spacing: -0.8px;
        img {
          width:        30px;
          margin-right: 8px;
        }
      }
      button {
        font-size:   20px;
        color:       #ffd600;
        padding:     6px 2px;
        font-weight: 900;
        white-space: nowrap;
        background: transparent;
        cursor:      pointer;
        height: 70px;
        width: 95%;
        border: none
      }
    }
  }
}