@import "./variables";
@import "./fonts";

body {
  background: black;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: "HK Grotesk", sans-serif;
}

img,
svg,
video,
iframe {
  width: 100%;
}

iframe {
  height: 49.25vw;
  max-height: 408px;
}

h2,
h3,
h4,
h5,
h6,
p,
li,
a {
  color: white;
}

h1 {
  font-family: "Libre Franklin", sans-serif;
  font-size: clamp(32px, 13.5vw, 100px);
  font-weight: 900;
  line-height: 0.8;
  letter-spacing: -2.8px;
}

h4 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 36px;
  letter-spacing: -1.5px;
}

.container {
  max-width: 964px;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
}

p,
li,
a {
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
}

p {
  margin-bottom: 20px;
}

a {
  transition: 0.3s linear;
  &:hover,
  &:active {
    opacity: 0.7;
  }
}

.btn {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  background: black;
  border: 3px solid white;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 900;
  text-decoration: none;
  color: white;
  min-width: 172px;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  transition: 0.3s linear;
  &:hover,
  &:active {
    opacity: 0.7;
  }
  &-yellow {
    border: 3px solid $yellow;
    &-outlined {
      border: 3px solid $yellow;
      background: $yellow;
      color: black;
    }
  }
  span {
    color: $yellow;
  }
  margin-top: 15px;
  margin-bottom: 30px;
}

label {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba($yellow, 50%);
  padding: 22px 10px;
  position: relative;
  .title {
    color: rgba(white, 50%);
    font-size: 18px;
    font-family: "HK Grotesk", sans-serif;
    font-weight: 500;
  }
  .icon {
    width: 18px;
    margin-right: 8px;
  }
  &.radio {
    display: flex;
    align-items: center;
    border-bottom: none;
    padding: 0 0 0 28px;
    user-select: none;
    margin-bottom: 20px;
    &:hover {
      .circle {
        border: 2px solid $yellow;
      }
    }
    .circle {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      border: 2px solid rgba(white, 50%);
      border-radius: 50%;
      position: relative;
      transition: 0.15s linear;
      &:before {
        opacity: 0;
        content: "";
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 80%;
        width: 80%;
        background: $yellow;
        border-radius: 50%;
        transition: 0.15s linear;
      }
    }
    input:checked ~ .circle {
      border: 2px solid $yellow;
      &:before {
        opacity: 1;
      }
    }
  }
}

.error {
  color: $yellow !important;
  font-size: 10px;
  position: absolute;
  z-index: 1;
  left: 10px;
  bottom: 6px;
  margin-bottom: 0;
  font-weight: 500;
}

.error-server {
  color: red;
  font-size: 12px;
}

input,
select {
  background: transparent;
  border: none;
  width: 100%;
  color: white;
  font-size: 18px;
  font-family: "HK Grotesk", sans-serif;
  font-weight: 500;
  &::placeholder {
    color: rgba(white, 0.5);
  }
}

.black-box {
  width: fit-content;
  padding: 10px;
  background-color: black;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (min-width: 992px) {
    padding: 20px 25px;
    margin-right: 20px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  appearance: auto !important;
  color: transparent !important;
  background-color: transparent !important;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: clamp(32px, 13.5vw, 50px);
    letter-spacing: -1.8px;
  }
  h4 {
    margin-bottom: 20px;
  }
  p,
  li,
  a {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 12px;
  }
}

@media screen and (max-width: 567px) {
  h1 {
    font-size: 40px;
  }
}
