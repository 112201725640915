.wrapper {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);

  font-family: "HK Grotesk", sans-serif;
  z-index: 10;
  p {
    margin:     0 !important;
    text-align: center;

    @media (min-width: 992px) {
      text-align: initial;
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 45px;
  padding: 0 20px;

  @media (min-width: 576px) {
    padding: 0;
  }

  .title {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;

    @media (min-width: 576px) {
      font-size: 48px;
    }
  }

  .closeBtn {
    border: none;
    background: transparent;
    color: white;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    top: 16px;
    transition: .3s linear;
    &:hover, &:active {
      opacity: .7;
    }
  }
}

.footer {
  width: 377px;

  @media (min-width: 576px) {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    margin-top: 70px;
  }

  @media (min-width: 1200px) {
    margin-top: 90px;
  }

  .footerText {
    font-weight: 400;
    font-size: 10px;
    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
}
